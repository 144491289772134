import { useQuery } from 'react-query'
import { getRentDates } from '../api'

export const useGetRentDates = () => {
  return useQuery(
    ['rent-dates'],
    () => getRentDates(),
    { keepPreviousData: true },
  )
}
