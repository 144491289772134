import React, { useEffect, useState } from 'react'
import { Modal, StarRating } from 'common'
import { Collapse } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ArrowRightOutline, ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerProduct,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
  KVProklamasi1,
  KVMerdeka,
  KVProklamasi2,
  BatterySwap2,
  H3199New,
  H3239New,
  SepekanMain,
  SepekanBetween,
  PBB,
  PSB,
} from 'assets/images'
import CostCalculator from 'components/cost-calculator'

const HomeV2 = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [color, setColor] = useState('light')
  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  // TESTIMONY
  const testimoniesList = [
    {
      rate: 5,
      name: 'Rian Ernest',
      occupation: 'Politisi & Pengacara',
      picture: Reviewer1,
      reviewBold: '“Enak banget manuvernya,',
      review: 'terutama pas macet. Torsinya terjaga dan bentuknya compact jadi enak banget.”',
    },
    {
      rate: 5,
      name: 'Putra Pratama Purwanto',
      occupation: 'Eksekutif Muda',
      picture: Reviewer2,
      reviewBold: '"Praktis banget buat swap & go.',
      review: 'Tukar baterainya nggak lama. Terus, buat bayar energi juga simpel, bisa langsung transaksi di aplikasi.”',
    },
    {
      rate: 5,
      name: 'Opik',
      occupation: 'Pemerhati Otomotif 10+ tahun',
      picture: Reviewer3,
      reviewBold: '"Murah banget',
      review: 'ketimbang bensin! Beli 5 Electrum Pulsa (EP) seharga 15rb aja bisa buat jarak hingga 120 km."',
    },
  ]
  const renderTestimonies = () => {
    return testimoniesList.map((item, index) => (
      <div
        key={index}
        style={{
          minWidth: '17.5rem',
          padding: '1.75rem',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
        }}
      >
        {/* TOP */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <img effect='blur' src={item.picture} alt='user' width={40} />
            <div style={{ marginLeft: '0.65rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', color: '#103856', fontSize: '1rem' }}>
                {item.name}
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856', fontSize: '0.85rem' }}>
                {item.occupation}
              </div>
              <StarRating rate={5} />
            </div>
          </div>
        </div>
        {/* SEPARATOR */}
        <div style={{ borderBottom: '0.15rem #ECECEC solid', margin: '1rem 0' }} />
        {/* REVIEW */}
        <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856', fontSize: '1rem' }}>
          <b>{item.reviewBold}</b>{' '}{item.review}
        </div>
      </div>
    ))
  }

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerProduct,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
      KVProklamasi1,
      KVProklamasi2,
    ]
    cacheImages(imgs)
  }, [])

  const faqsList = [
    {
      key: 1,
      label: 'Apakah Electrum sudah dijual bebas di Indonesia untuk umum atau hanya untuk sewa?',
      children: <p>
        Anda dapat secara langsung membeli motor listrik Electrum H3, baterai, serta charger. Anda juga dapat melakukan penyewaan baterai untuk opsi yang lebih efisien.
      </p>,
    },
    {
      key: 2,
      label: 'Apakah sewa baterai berdasarkan pemakaian atau berlangganan bulanan?',
      children: <p>
        Pembayaran biaya sewa baterai dilakukan setiap bulan selama kepemilikan motor.
      </p>,
    },
    {
      key: 3,
      label: 'Di mana saja lokasi Battery Swap Station (BSS) Electrum?',
      children: <p>
        BSS Electrum telah tersebar di lebih dari 200 lokasi di Jakarta. Anda dapat menemukan lokasi BSS yang terdekat dengan area Anda di <a href='https://bit.ly/bsselectrum' target='_blank'>https://bit.ly/bsselectrum</a>.
      </p>,
    },
    {
      key: 4,
      label: 'Di mana saja lokasi kantor dealer/showroom Electrum?',
      children: <p>
        Anda dapat mengunjungi Electrum Sales Center Pondok Indah Jakarta untuk melakukan test ride maupun pembelian unit.
      </p>,
    },
    {
      key: 5,
      label: 'Metode pembayaran apa saja yang tersedia untuk membeli motor Electrum?',
      children: <p>
        Electrum H3 bisa Anda dapatkan melalui metode pembayaran secara cash, kredit, leasing ataupun melalui e-commerce seperti Tokopedia. Harga yang tercantum dalam promo sudah termasuk subsidi pemerintah.
      </p>,
    },
  ]
  const renderFAQsList = () => {
    return faqsList.map((item, index) => {
      return (
        <Collapse
          bordered={false}
          items={[faqsList[index]]}
          style={{
            backgroundColor: '#fff',
            marginBottom: (faqsList.length - 1) !== index && '0.65rem',
            fontFamily: 'Gilroy-Medium',
          }}
        />
      )
    })
  }

  /* WhatsApp Text Templace
  const textChat = encodeURIComponent(`Hi! I am interested in your electric motorcycles. [Electrum] (${window.location.href})`)
  */

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  // PRICE SCHEMA
  const schema1 = {
    title: 'Paket Beli Baterai',
    description: 'Motor + Baterai + Charger',
    price: 23.9,
    subdescription: 'OTR Jabodetabek',
    kv: PBB,
    points: [
      'Garansi baterai 5 tahun/50 ribu km',
      '<b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)',
      '<b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)',
      '<b>GRATIS</b> Home Service 1 tahun ',
      '<b>GRATIS</b> Emergency Road Assistance 1 tahun',
      '<b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)',
      '<b>GRATIS</b> helm Electrum dan toolkit',
    ],
    button_text: 'Pilih Paket',
  }
  const schema2 = {
    title: 'Paket Sewa Baterai',
    description: 'Motor + Sewa Baterai + Swap',
    price: 19.9,
    subdescription: 'OTR Jabodetabek',
    kv: PSB,
    points: [
      '<b>GRATIS</b> sewa baterai selama 18 bulan',
      '<b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)',
      '<b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)',
      '<b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)',
      '<b>GRATIS</b> helm Electrum dan toolkit',
    ],
    button_text: 'Pilih Paket',
  }
  const PriceCard = ({
    title,
    description,
    price,
    subdescription,
    kv,
    points,
  }) => {
    return (
      <div style={{
        padding: '1.5rem 2rem',
        backgroundColor: 'white',
        borderRadius: '0.85rem',
        boxShadow: '0px 3px 12px -2px rgba(0,0,0,0.1)',
      }}>
        {/* <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#0B4571', marginBottom: '1rem', textAlign: 'center' }}>
          {title}
        </div>
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#516692', margin: '0rem 3rem 1rem 3rem', textAlign: 'center' }}>
          {description}
        </div>
        <div style={{
          fontFamily: 'Gilroy-Medium',
          fontSize: '0.85rem',
          color: '#0B4571',
          margin: '0rem 3rem 1rem 3rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '0.5rem',
        }}>
          <div>Rp</div> {' '}
          <div style={{ fontSize: '1.65rem' }}>{price}</div> {' '}
          <div style={{ color: '#24608D' }}><b>Juta</b></div>
        </div>
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#516692', margin: '0rem 3rem 1.5rem 3rem', textAlign: 'center' }}>
          {subdescription}
        </div> */}
        <img alt='illustration' src={kv} style={{ width: '100%' }} />
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#516692', marginLeft: '-1rem' }}>
          <ul>
            {points.map((item, index) => (
              <li key={index} style={{ marginBottom: '0.85rem' }}>
                {/* {item} */}
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  // TOP BANNER CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    {
      img: SepekanMain,
      clickable: true,
      link: '/sepekan-form',
    },
    {
      img: H3239New,
      clickable: false,
      link: '',
    },
    {
      img: H3199New,
      clickable: false,
      link: '',
    },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <img
        effect='blur'
        alt='top-banner'
        src={item.img}
        loading='eager'
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          maxHeight: '100%',
          maxWidth: '100%', 
          objectFit: 'cover',
          cursor: item.clickable ? 'pointer' : 'initial',
        }}
        onClick={() => {
          item.clickable && navigate(item.link)
        }}
      />
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('proklamasi-kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % KVList.length
      setCurrentIndex(nextIndex)
      const carousel = document.getElementById('proklamasi-kv-carousel')
      carousel.scrollTo({ left: nextIndex * carousel.clientWidth, behavior: 'smooth' })
    }, 2000)
    return () => clearInterval(interval)
  }, [currentIndex, KVList.length])
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('proklamasi-kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }
  

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#F0F8FE',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* SCROLL POSITION POPUP */}
        {showPopup && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: 'lightgrey',
            border: '1px solid black',
            color: 'red',
            fontWeight: 600,
            zIndex: -99999,
          }}>
            Scroll position: {scrollPosition.scrollTop} <br />
            {isScrolledToBottom && <div>You have scrolled to the bottom!</div>}
          </div>
        )}

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: 9999,
        }}>
          <div style={{
            display: 'flex',
            // justifyContent: 'space-between',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer', visibility: 'hidden' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* TOP BANNER PRODUCT */}
        <div>
          {/* IMAGE CAROUSEL */}
          <div style={{ position: 'relative', width: '100%' }}>
            <div
              id="proklamasi-kv-carousel"
              style={{
                margin: '0 auto',
                backgroundColor: 'white',
                display: 'flex',
                overflowX: 'scroll',
                scrollSnapType: 'x mandatory',
                scrollSnapStop: 'always',
              }}
              onScroll={(e) => {
                const container = e.target
                const scrollPosition = container.scrollLeft
                const containerWidth = container.clientWidth
                const newIndex = Math.round(scrollPosition / containerWidth)
                setCurrentIndex(newIndex)
              }}
            >
              {renderKVCarousel()}
              {/* Previous Button */}
              <button
                onClick={() => handleScrollCarousel(-1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  fontWeight: 600,
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === 0 ? 'none' : 'block',
                }}
              >
                &#8249;
              </button>
              {/* Next Button */}
              <button
                onClick={() => handleScrollCarousel(1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  fontWeight: 600,
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === KVList.length-1 ? 'none' : 'block',
                }}
              >
                &#8250;
              </button>
            </div>
          </div>
          {/* SLIDE INDICATOR */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              position: 'relative',
            }}
          >
            {KVList.map((item, index) => (
              <div
                key={index}
                onClick={() => handleIndicatorClick(index)}
                style={{
                  width: '30px',
                  height: '7.5px',
                  borderRadius: '50rem',
                  backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
              ></div>
            ))}
          </div>
        </div>

        {/* COST CALCULATOR */}
        <div style={{ padding: '1rem 0', marginTop: '1rem' }}>
          <CostCalculator />
        </div>

        {/* SEPEKAN KV */}
        <img
          alt='sepekan-between'
          src={SepekanBetween}
          style={{ width: '100%', marginBottom: '1rem', cursor: 'pointer' }}
          onClick={() => navigate('/sepekan-form')}
        />

        {/* COLOR & VEHICLE */}
        <div style={{ backgroundColor: '#fff', padding: '2rem' }}>
          <div style={{ fontSize: '1.875rem' }}>
            <span style={{ fontFamily: 'Gilroy-Bold' }}>Kendaraan yang</span> <br />
            <span style={{ fontFamily: 'Gilroy-ExtraBold' }}>Mengerti Kamu</span>
          </div>
          {
            color === 'light'
            ? (<img effect='blur' alt='h3-light' src={H3Light} style={{ width: '100%', marginBottom: '1.5rem' }} loading='eager' />)
            : (<img effect='blur' alt='h3-dark' src={H3Dark} style={{ width: '100%', marginBottom: '1.5rem' }} loading='eager' />)
          }
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            // width: '5.5rem',
            rowGap: '0.5rem',
            marginBottom: '1.5rem',
          }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#103856' }}>
              Pilihan Warna:
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div
                onClick={() => setColor('light')}
                style={{
                  cursor: 'pointer',
                  height: '1.125rem',
                  width: '1.125rem',
                  borderRadius: '0.125rem',
                  backgroundColor: '#1ECECA',
                  border: color === 'light' ? '2px solid #F04C3E' : 'none',
                }}
              />
              <div
                onClick={() => setColor('dark')}
                style={{
                  cursor: 'pointer',
                  height: '1.125rem',
                  width: '1.125rem',
                  borderRadius: '0.125rem',
                  backgroundColor: '#103856',
                  border: color === 'dark' ? '2px solid #F04C3E' : 'none',
                }}
              />
            </div>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: color === 'light' ? '#1ECECA' : '#103856' }}>
              {color === 'light' ? 'Derawan Blue' : 'Midnight Bromo'}
            </div>
          </div>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', marginBottom: '1.5rem' }}>
            Electrum H3
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#848484' }}>
            Electrum H3 hadir dengan bobot ringan sebagai penunjang pejuang perkotaan yang lincah. Terintegrasi dengan ratusan jaringan BSS (Battery Swap Station) di Jakarta, Anda akan merasakan kemudahan mendapatkan baterai yang selalu baru dalam hitungan detik. Selamat datang di ekosistem mobilitas listrik yang baru!
          </div>
        </div>

        {/* PRICING */}
        {/* <div style={{ backgroundColor: '#fff', padding: '0 2rem 2rem 2rem' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            backgroundColor: '#F4F7FA',
            borderRadius: '0.625rem',
          }}>
            <div style={{
              fontFamily: 'Gilroy-ExtraBold',
              fontSize: '1rem',
              color: 'white',
              backgroundColor: '#F04C3E',
              padding: '0.75rem 2.5rem 0.75rem 0.75rem',
              borderRadius: '0.625rem 0 6.25rem 0',
            }}>
              Harga Promo
            </div>
            <div style={{ padding: '0.75rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div id='slashed-price-1' style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1rem', color: '#565656' }}>
                Rp 22.900.000
              </div>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.25rem' }}>
                Rp 14.900.000
              </div>
            </div>
          </div>
        </div> */}

        {/* PRICE OPTIONS */}
        <div style={{ padding: '2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginTop: '1rem', marginBottom: '0.5rem' }}>
            Pilihan Harga
          </div>
          <PriceCard
            title={schema1.title}
            description={schema1.description}
            price={schema1.price}
            subdescription={schema1.subdescription}
            points={schema1.points}
            kv={schema1.kv}
          />
          <PriceCard
            title={schema2.title}
            description={schema2.description}
            price={schema2.price}
            subdescription={schema2.subdescription}
            points={schema2.points}
            kv={schema2.kv}
          />
        </div>

        {/* LEASING */}
        <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1.5rem' }}>
            Leasing
          </div>
          <img effect='blur' alt='card-placeholder' src={Lease} style={{ width: '100%', marginBottom: '1.5rem' }} loading='eager' />
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#0B4571', marginBottom: '0.5rem' }}>
            Hubungi kami untuk special rate!
          </div>
          {/* <a
            href='https://wa.me/6285190013535'
            target='_blank'
            rel="noreferrer"
            style={{
              cursor: 'pointer',
              color: '#fff',
              textDecoration: 'none',
              display: 'flex',
              columnGap: '0.65rem',
              marginTop: '1rem',
            }}
          >
            <div style={{
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '1.125rem',
              backgroundColor: '#0B4571',
              padding: '0.6rem 0.75rem',
              width: 'fit-content',
              borderRadius: '0.75rem',
              display: 'flex',
            }}>
              Chat Now &nbsp; <ArrowRightOutline />
            </div>
          </a> */}
          <a
            href='https://drive.google.com/file/d/1OnzouXb07WVTp636armOiUq61vCpl8AE/view?usp=sharing'
            target='_blank'
            rel="noreferrer"
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              textDecoration: 'none',
              display: 'flex',
              columnGap: '0.65rem',
              width: 'fit-content',
            }}
          >
            <div style={{
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '1.125rem',
              margin: '0.5rem 0',
              width: 'fit-content',
              borderRadius: '0.75rem',
              display: 'flex',
            }}>
              Cicilan ADIRA &nbsp; <ArrowRightOutline color="#0B4571" />
            </div>
          </a>
          <a
            href='https://drive.google.com/file/d/1ePhZGp0Pqkz66d3BttfxMxXNmXZGztaD/view?usp=sharing'
            target='_blank'
            rel="noreferrer"
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              textDecoration: 'none',
              display: 'flex',
              columnGap: '0.65rem',
              width: 'fit-content',
            }}
          >
            <div style={{
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '1.125rem',
              margin: '0.5rem 0',
              width: 'fit-content',
              borderRadius: '0.75rem',
              display: 'flex',
            }}>
              Cicilan AEON &nbsp; <ArrowRightOutline color="#0B4571" />
            </div>
          </a>
          <a
            href='https://drive.google.com/file/d/1pHyxeh-UMIoyl6MMtklgC2e_Amopt_ZZ/view?usp=sharing'
            target='_blank'
            rel="noreferrer"
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              textDecoration: 'none',
              display: 'flex',
              columnGap: '0.65rem',
              width: 'fit-content',
            }}
          >
            <div style={{
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '1.125rem',
              margin: '0.5rem 0',
              width: 'fit-content',
              borderRadius: '0.75rem',
              display: 'flex',
            }}>
              Cicilan CIMB &nbsp; <ArrowRightOutline color="#0B4571" />
            </div>
          </a>
        </div>

        {/* SPECIFICATION */}
        <img effect='blur' alt='specification' src={Specification} style={{ width: '100%', margin: '1.5rem 0' }} loading='eager' />

        {/* TRUSTED */}
        <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1.5rem' }}>
            Dipercaya Pengguna<br />
            Kami
          </div>
          <img effect='blur' alt='mileage' src={Mileage} style={{ width: '100%', margin: '1rem 0' }} loading='eager' />
          <img effect='blur' alt='riders' src={Riders} style={{ width: '100%', margin: '1rem 0' }} loading='eager' />
        </div>

        {/* TESTIMONIALS */}
        <div style={{ padding: '2rem 0' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1.5rem', paddingLeft: '2rem' }}>
            Ini Kata Mereka!
          </div>
          <div style={{
            padding: '1rem 2rem',
            display: 'flex',
            overflowX: 'scroll',
            columnGap: '1.25rem',
            scrollPaddingLeft: '6rem',
          }}>
            {renderTestimonies()}
          </div>
        </div>

        {/* FUTURE */}
        <img effect='blur' alt='future' src={Future} style={{ width: '100%' }} />

        {/* 8 ELEMENTS */}
        <div>
          {/* EFFICIENT */}
          <div style={{ padding: '2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Efisien Waktu
            </div>
            <img effect='blur' alt='card-placeholder' src={Efficient2} style={{ width: '100%' }} loading='eager' />
            <img effect='blur' alt='card-placeholder' src={Efficient1} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* BATTERY SWAP */}
          <img effect='blur' alt='battery-swap' src={BatterySwap2} style={{ width: '100%', margin: '1.5rem 0 3.5rem 0' }} loading='eager' />

          {/* DANGER FREE */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Lega dari Bahaya
            </div>
            <img effect='blur' alt='card-placeholder' src={DangerFree1} style={{ width: '100%' }} loading='eager' />
            <img effect='blur' alt='card-placeholder' src={DangerFree2} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* RENEWABLE ENERGY */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Energi Terbarukan
            </div>
            <img effect='blur' alt='card-placeholder' src={Renewable1} style={{ width: '100%' }} loading='eager' />
            <img effect='blur' alt='card-placeholder' src={Renewable2} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* SMART */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Cerdas!
            </div>
            <img effect='blur' alt='card-placeholder' src={Smart1} style={{ width: '100%' }} loading='eager' />
            <img effect='blur' alt='card-placeholder' src={Smart2} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* LATEST */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Terkini
            </div>
            <img effect='blur' alt='card-placeholder' src={Latest1} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* SPACIOUS */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Ruang yang Luas
            </div>
            <img effect='blur' alt='card-placeholder' src={Spacious1} style={{ width: '100%' }} loading='eager' />
            <img effect='blur' alt='card-placeholder' src={Spacious2} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* UNIQUE */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Unik
            </div>
            <img effect='blur' alt='card-placeholder' src={UniqueLong} style={{ width: '100%' }} loading='eager' />
            <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', margin: '1.25rem 0' }} />
          </div>

          {/* CALM */}
          <div style={{ padding: '0 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem' }}>
              Menenangkan
            </div>
            <img effect='blur' alt='card-placeholder' src={Calm1} style={{ width: '100%' }} loading='eager' />
            <img effect='blur' alt='card-placeholder' src={Calm2} style={{ width: '100%' }} loading='eager' />
          </div>
        </div>

        {/* FLEXIBLE CHOICE */}
        <div style={{ padding: '0 2rem 2rem 2rem' }}>
          <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', marginBottom: '2rem' }} />
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1.5rem' }}>
            Pilihan yang Fleksibel
          </div>
          <img effect='blur' alt='rectangle-card' src={Flexible} style={{ width: '100%' }} loading='eager' />
        </div>

        {/* AUTHENTIC SERVICE CENTER */}
        <div style={{ padding: '0 2rem 2rem 2rem' }}>
          <div style={{ borderBottom: '0.625rem solid #1ECECA', width: '5.5rem', marginBottom: '2rem' }} />
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1.5rem' }}>
            Layanan Terbaik dari Service Center Autentik
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#103856', marginBottom: '2rem' }}>
            Jadwalkan servis berkala motor listrik Electrum Anda.
            <br /><br />
            Penggantian spare part resmi dan berstandar.
            <br /><br />
            GRATIS konsultasi dengan tenaga mekanik yang andal.
          </div>
          <img effect='blur' alt='rectangle-card' src={ServiceCenter} style={{ width: '100%' }} loading='eager' />
        </div>

        {/* SERVICE CENTER ADDRESS */}
        <div style={{ padding: '0 2rem 2rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', marginBottom: '1rem' }}>
            Electrum Service Center
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#848484', marginBottom: '1rem' }}>
            Jl. Sultan Iskandar Muda No.Kav.29, RT.7/RW.9, Pondok Indah, Kebayoran Lama, Jakarta Selatan 12240
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#848484', marginBottom: '1rem' }}>
            Senin s/d Jumat : 09.00-17.00 WIB <br />
            Sabtu : 09.00-12.00 WIB <br />
            Minggu : Libur <br />
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#848484' }}>
            Electrum Contact Center: &nbsp;
            <a
              href='https://wa.me/6281111804003?text=Halo%21%20Saya%20ingin%20info%20lebih%20lanjut%20mengenai%20Electrum%20H3.'
              target='_blank'
              rel='noreferrer'
              style={{ color: '#848484' }}
            >
              081111-804-003
            </a>
          </div>
        </div>

        {/* SERVICE CENTER BRANCH */}
        <div style={{ padding: '0rem 2rem 2rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', marginBottom: '1rem' }}>
            Cabang Service Center
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#848484' }}>
            (Akan hadir) Seluruh gerai Planet Ban di Jakarta
          </div>
        </div>

        {/* FAQ */}
        <div style={{ padding: '0rem 2rem 1.5rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1rem' }}>
            FAQ
          </div>
          {renderFAQsList()}
        </div>

        {/* COPYRIGHT */}
        <div style={{ padding: '1.5rem 2rem 5rem 2rem', backgroundColor: '#1cceca' }}>
          <div style={{ fontFamily: 'Gilroy-Semibold', fontSize: '0.75rem', color: 'white' }}>
            Copyright 2024 &#169; PT. Energi Kreasi Bersama
          </div>
        </div>

        {/* FIXED SECTION */}
        <div style={{
          position: 'fixed',
          bottom: '0',
          backgroundColor: '#fff',
          borderRadius: '1.25rem 1.25rem 0 0',
          width: isMobile ? '100%' : '33%',
        }}>
          <div style={{ padding: '0.85rem', display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <a
              href='https://wa.me/6285190013535?text=Halo%21%20Saya%20ingin%20info%20lebih%20lanjut%20mengenai%20Electrum%20H3.'
              target='_blank'
              rel='noreferrer'
              style={{ height: '35px' }}
            >
              <img effect='blur' alt='chat-with-us' src={ChatWithUs} width={35} style={{ cursor: 'pointer' }} loading='eager' />
            </a>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                color: '#225E8B',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: '2px solid #225E8B',
                padding: '0.25rem 0.75rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => window.open(
                'https://wa.me/6285190013535?text=Halo%21%20Saya%20mau%20buat%20jadwal%20booking%20test%20ride%20Electrum%20H3.',
                '_blank',
                'rel=noopener noreferrer'
              )}
            >
              Daftar Test-ride
            </button>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: '#225E8B',
                borderRadius: '0.5rem',
                color: 'white',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: 'none',
                padding: '0.25rem 0.75rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => setChooseModal(true)}
            >
              Buy Now
            </button>
          </div>
        </div>

        {/* CHOOSE MODAL */}
        <Modal
          closeBtn={true}
          onClose={() => setChooseModal(false)}
          isOpen={chooseModal}
        >
          <div style={{ fontSize: '1.5rem', fontFamily: 'Gilroy-Bold', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.25rem' }}>
            Amankan Promonya Sekarang
          </div>
          <div style={{ fontSize: '0.85rem', fontFamily: 'Gilroy-SemiBold', color: '#848484', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.75rem' }}>
            Beli Electrum H3 melalui platform terpercaya kami.
          </div>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
              marginBottom: '1rem',
            }}
            onClick={() => {
              window.open(
                'https://wa.me/6285190013535?text=Halo%21%20Saya%20mau%20promo%20Electrum%20H3.',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Whatsapp Official Electrum
          </button>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
            }}
            onClick={() => {
              window.open(
                'https://tokopedia.link/electrumindonesia',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Tokopedia
          </button>
        </Modal>

      </div>
    </div>
  )
}

export default HomeV2
